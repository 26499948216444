<template>
  <!-- 小程序码 -->
  <a-modal v-model:visible="appletShow" title="二维码" footer="" @cancel="appletShow = false">
    <div class="QRC">
      <!-- <img v-show="!QRCloading" :src="QRCurl" style="width:50%" /> -->
      <div class="QRC-div">
        <a-radio-group v-model:value="appletType" size="large" @change="appletChange">
          <a-radio-button :value="2">
            <div style="display:flex;align-items:center">
              华月供应链
            </div>
          </a-radio-button>
          <a-radio-button :value="4">
            <div style="display:flex;align-items:center">
              车小象
            </div>
          </a-radio-button>
        </a-radio-group>
        <div style="text-align:center;margin-bottom:10px;width:100%">
          <div>起：{{ detailData.startAddress }}</div>
          <div>终：{{ detailData.endAddress }}</div>
          <div style="display:flex;justify-content: space-around;">
            <div>车架号：<a-tag color="green" v-for="(item, index) in detailData.vehicleList" :key="index">{{ item.vinNo
            }}</a-tag>
            </div>
          </div>
          <img v-show="!QRCloading" :src="QRCurl" style="width:50%" />
          <div v-if="appletType === 2" style="text-align: center;">识别小程序，查看车辆位置</div>
          <div v-if="appletType === 4" style="text-align: center;">
            <div>识别小程序查询↓</div>
            <div> 车辆验车照片，每日车辆运输位置，车辆运输保险单</div>
            <div class="m-t1">小程序内查询全国运车线路实时报价</div>
            <div >全国运车服务热线-4007272777</div>
          </div>
        </div>
        <a-spin v-show="QRCloading" tip="Loading..." />
      </div>
    </div>
  </a-modal>
</template>

<script>
import { reactive, toRefs } from 'vue'
import { QRCurl } from '@/api/transport/inspection'

export default {
  props: {
    detailData: Object
  },
  setup (props) {
    const state = reactive({
      appletShow: false,
      QRCloading: false,
      appletType: 2,
      detailData: props.detailData,
      QRCurl: '',
    })
    const appletChange = () => {
      state.QRCloading = true
      const wXprogramQRcode = { scene: props.detailData.orderId, type: state.appletType }
      QRCurl(wXprogramQRcode)
        .then(res => {
          if (res.code === 10000) {
            state.QRCurl = 'data:image/png;base64,' + res.data
            if (state.QRCurl !== '' || state.QRCurl !== null || state.QRCurl !== undefined) state.QRCloading = false
          }
          // console.log(res)
        }).finally(() => { state.QRCloading = false })
    }
    return {
      ...toRefs(state),
      appletChange
    }
  }
}
</script>

<style lang="less" scoped>
@import './index.less';
</style>