import { postBodyRequest, postRequest, getRequest } from '@/utils/axios'
// 中控台
export function businessStatistics (params) {
  return postBodyRequest('/anal/business-center-console/statistics', params)
}
// 订单返款-客户统计
export function orderRefundTotal (params) {
  return postBodyRequest('/anal/business-center-console/order-refund-total', params)
}
// 订单返款-明细
export function orderRefundDetails (params) {
  return postBodyRequest('/anal/business-center-console/order-refund-details', params)
}
// 订单开票-客户统计
export function orderInvoiceTotal (params) {
  return postBodyRequest('/anal/business-center-console/order-invoice-total', params)
}
// 订单开票-明细
export function orderInvoiceDetails (params) {
  return postBodyRequest('/anal/business-center-console/order-invoice-details', params)
}
// 应收看板
export function receivableTotal (params) {
  return postBodyRequest('/anal/business-center-console/receivable-total', params)
}
// 所有人年月利润列表
export function profitList (params) {
  return postBodyRequest('/anal/business-center-console/sale/profit-list', params)
}
// 财务-利润员工明细
export function empEmpId (empId,params) {
  return postBodyRequest(`/anal/business-center-console/emp/${empId}`,params)
}
// 跟进明细
export function customerFollowDetails (params) {
  return postBodyRequest(`/anal/business-center-console/customer-follow-details`,params)
}
// 客户查询
export function customerSearch (params) {
  return postBodyRequest(`/anal/business-center-console/customer/search`,params)
}
// 业务员添加搜索分组list
export function groupList (params) {
  return postBodyRequest(`/anal/search/group/list`,params)
}
// 找客户接口
export function groupCustomer(params) {
  return postBodyRequest(`/anal/search/group/customer`,params)
}
// 业务员添加搜索分组
export function groupAdd(params) {
  return postBodyRequest(`/anal/search/group/add`,params)
}
// 添加回款计划
export function planAdd(params) {
  return postBodyRequest(`/anal/receive/plan/add`,params)
}
// 待下载合同列表
export function downloadContractList(params) {
  return postBodyRequest(`/anal/business-center-console/download-contract/list`,params)
}
// 待下载保险列表
export function downloadInsuranceList(params) {
  return postBodyRequest(`/anal/business-center-console/download-insurance/list`,params)
}
// 保单已下载
export function insuranceDw(orderId) {
  return getRequest(`/trans/order/insurance/download/${orderId}`)
}
// 合同已下载
export function contractDw(orderId) {
  return getRequest(`/trans/order/contract/${orderId}`)
}
// 合同到期列表
export function customerContractList(params) {
  return postBodyRequest(`/anal/business-center-console/customer-contract/list`,params)
}
// 渠道转化率
export function conversionChannel(params) {
  return postBodyRequest(`/anal/business-center-console/conversion-channel`,params)
}
// 未投保列表
export function vehicleInsuranceNoList(params) {
  return postBodyRequest(`/anal/business-center-console/vehicle-insurance-no/list`,params)
}
// 删除分组
export function groupDelete(params) {
  return getRequest(`/anal/search/group/delete`,params)
}
// 附加费列表-待审核
export function expenseReportList(params) {
  return postBodyRequest(`/anal/business-center-console/expense-report/list`,params)
}
// 分析异常列表-待审核
export function vehicleAnalysisList(params) {
  return postBodyRequest(`/anal/business-center-console/vehicle-analysis/list`,params)
}
// 新增-发起车辆未投保-报备
export function uninsuredAdd(params) {
  return postBodyRequest(`/anal/vehicle/uninsured/add`,params)
}
// 添加关注
export function focusAdd(params) {
  return postBodyRequest(`/anal/business/focus/add`,params)
}
// 取消关注
export function focusCancel(params) {
  return postBodyRequest(`/anal/business/focus/cancel`,params)
}
// 未投保列表-审核
export function uninsuredAuditList(params) {
  return postBodyRequest(`/anal/business-center-console/vehicle-uninsured-audit/list`,params)
}
// 审核未投保报备
export function uninsuredAudit(params) {
  return postBodyRequest(`/anal/vehicle/uninsured/audit`,params)
}
// 添加车辆亏损原因
export function lossAdd(params) {
  return postBodyRequest(`/anal/vehicle/loss/add`,params)
}
// 跟单异常明细
export function documentaryExceptionList(params) {
  return postBodyRequest(`/anal/business-center-console/documentary-exception-list`,params)
}
// 业务中台-内部查询价格
export function cline(params) {
  return postBodyRequest(`/market/c/end/business-offer`,params)
}
// 员工工作量
export function salesmanWorks(params) {
  return postBodyRequest(`/anal/business-center-console/salesman-works`,params)
}
// 百万豪车已投保列表
export function luxuryCarInsuranceList(params) {
  return postBodyRequest(`/anal/business-center-console/luxury-car-insurance/list`,params)
}
// 客户看板统计
export function salesmanCustomerVehicle(params) {
  return postBodyRequest(`/anal/business-center-console/salesman-customer_vehicle`,params)
}
// 应收明细
export function receivableDetails(params) {
  return postBodyRequest(`/anal/business-center-console/receivable-details`,params)
}
// 验车委托
export function commissionVehicle(params) {
  return postBodyRequest(`/anal/business-center-console/commission-vehicle`,params)
}
// 待送车
export function takeoutVehicle(params) {
  return postBodyRequest(`/anal/business-center-console/takeout-vehicle`,params)
}
// 车辆在途
export function transportVehicle(params) {
  return postBodyRequest(`/anal/business-center-console/transport-vehicle`,params)
}
// 发车计划
export function planVehicle(params) {
  return postBodyRequest(`/anal/business-center-console/plan-vehicle`,params)
}
// 即将到达
export function arrivedVehicle(params) {
  return postBodyRequest(`/anal/business-center-console/arrived-vehicle`,params)
}
// 关注客户列表
export function focusCustomer(params) {
  return postBodyRequest(`/anal/business-center-console/focus-customer`,params)
}
// 代打标签
export function waitLabel(params) {
  return postBodyRequest(`/anal/business-center-console/wait-label`,params)
}
// 回款历史记录
export function receivePlanId(id,params) {
  return getRequest(`/anal/business-center-console/receive-plan/${id}`,params)
}
// 订单开票查询
export function orderInvoiceSearch(params) {
  return postBodyRequest(`/anal/business-center-console/order-invoice/search`,params)
}